.custom-input-field {
  width: 100%;
  min-height: 30px;
  color: #66799e;
  padding-left: 15px;
  border: 0.0625rem solid #d1d7e0;
  font-size: 10px;
}
.parent-center-screen{
    width:100vw;
    height:100vh;
    position: fixed;
    background: #000000ba;
    top: 0;
    left: 0;
    z-index: 99999;
}
.center-screen{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.custom-container:has(div){
  // width: 69%;
  margin-top: 63%;

  .player{
    margin-top: 345px !important;
  }
}


@media only screen and (max-width: 580px) {
  .DateRangePickerWithoutInputWrapper{
    margin-bottom: 30px;
  }
  .custom-container{
    position: absolute;
    top: 85px;
    left: 30px;
  }
  .noOption .custom-container{
    top: 55px;
  }
}

