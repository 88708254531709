.h4-overview {
    margin: 10px 10px 0 10px;
}

.div-overview {
    margin: 5px 15px 15px 15px
}

.h4-hr {
    margin: 10px 0 15px 0;
}

.col-padding {
    padding-right: 0%;
}