.card-container {
  max-width: 400px;
  margin: auto;
  margin-top: 24px;
}

.red-color {
  color: red;
}

.width-spacing {
  width: 130px;
  cursor: pointer;
}