.scrollable{
    max-height: 70vh;
    overflow: hidden;
}
.provider{
    position: relative;
    top: 7px;
}
.wagering-table{
        height: 500px ;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 20px;
    }

.wageering-template-checkbox{
    float: left;
    cursor: pointer;
}
.small-size-error{
    font-size: 13px;
}
