.searchWrapper {
  padding: 0.2rem 0.3rem !important;
  font-size: 0.875rem !important;
  border-radius: 10px !important;
  .searchBox {
    margin-top: 0px !important;
  }
  input::-webkit-input-placeholder {
    color: #66799e !important;
  }
}

.optionListContainer {
   li {
    padding: 3px 10px !important;
    font-size: 0.875rem !important;
  }
}