.green-text{
    color: green;
}
.gray-text{
    color: gray;
    font-size: 10px;
    line-height: .2;
    text-transform: uppercase;
}
.profile-image{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}
.graph-container{
    min-height: 170px;
}
.device-report{
    font-size: 12px;
    line-height: .5;
    text-transform: capitalize;
}
.hr-line{
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}