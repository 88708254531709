/*
 * Sidebar
*/
.sidebar {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	max-height: 100%;
	overflow-y: auto;
	width: 100%;
	@include transition(max-width .3s);

	@include media-breakpoint-up(md) {
		width: 100%;
		max-width: 285px;
	}

	.nav {
		white-space: nowrap;
	}

	.nav-item {
		border: $border-width solid transparent;
		&.active {
			& > .nav-link {
				color: $primary;
				background-color: $gray-400;
				border: $border-width solid lighten( $dark, 20% );
				.sidebar-icon {
						color: $primary;
					}
			}
		}
		.nav-link {
			color: $light;
			border: $border-width solid $dark;
			&:hover {
				color: $primary;
					background-color: $gray-400;
					border: $border-width solid lighten($dark, 20%);
					.sidebar-icon {
						color: $primary;
					}
			}
			&.active {
				color: $white;
			}
		}
		margin-bottom: .2rem;
	}

	.nav-link + .multi-level {
		margin-top: .2rem;
	}

	.nav-link {
		// font-size: $font-size-base;
    font-size: 13px;
		vertical-align: middle;
		padding: 0.55rem 0.75rem;
		@include border-radius($border-radius);

		.sidebar-icon {
			margin-right: .5rem;
			color: $white;
			span{
				min-width: 22px;
			}
			svg{
				min-width: 22px;
			}
			&.svg-icon{
				margin-right:14px;
			}
		}

		.link-arrow{
			font-size: $font-size-sm;
		}

		&:focus{
			outline: none;
			box-shadow: 0 0 0 0.1rem rgba(#4c5680, 0.2);
		}

	}
	.nav-link.active {
		color: $primary;
	}

	.multi-level{
		.nav-link{
        padding-left: 30px;
		}
	}

	.sidebar-text,
	.link-arrow,
	.badge,
	.notification-count {
		opacity: 1;
		@include transition(opacity .3s);
	}

	@include media-breakpoint-up(md) {
		.sidebar-text-contracted {
			display: none;
			@include transition(opacity .3s);
		}

		&.contracted {
			.sidebar-text,
			.link-arrow,
			.badge {
				opacity: 0;
			}

			.notification-count {
				opacity: 1;
				position: absolute;
				top: 0;
				left: 35px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				font-size: .7rem;
				padding: .2rem;
			}

			.nav-item {
				position: relative;
				width: 47px;
				&.active {
					& > .nav-link {
						background-color: $primary;
					}
				}
				.nav-link {
					&:hover {
						background-color: $primary;
					}
				}
			}

			.sidebar-text-contracted {
				display: inline;
			}

			.multi-level .nav-link {
				padding-left: 17px;
			}

			.nav-item {
				white-space: nowrap;
			}

			.sidebar-icon {
				text-align: center;
			}


			@include media-breakpoint-up(md) {
				& + .content {
					margin-left: 95px;

				}
			}

			max-width: 95px;
		}
	}
}

.sidebar-inner {
	position: relative;
	overflow-y: hidden;
	.accordion-button{
		&::after {
			background-image: none;
			content: '\f054';
			font-family: $font-awesome-5;
			font-size: $font-size-sm;
			font-weight: 900;
			height:1.25rem;
			width:.5rem;
		}
		&:not(.collapsed) {
			background-color:transparent;
			&::after{
				transform: rotate(90deg);
    			transition: all .2s ease;
			}
		}
	}

	.accordion {
		padding-top: .25rem;
	}

	.accordion-body {
		padding: .5rem 0 0 0;
	}

	.accordion-collapse{
		border:0;
	}
}

.sidebar-heading {
	font-size: .75rem;
	text-transform: uppercase;
}

.user-card{
	border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
	.sidebar {
		width: 100%;
	}
}

.content {

	overflow: visible;
	padding: 0 $spacer 0 $spacer;
	@include transition(all .3s);

	@include media-breakpoint-up(md) {
		margin-left: 285px;
	}
}

.sidebar-toggle {
	font-size: 1.3rem;

	&:hover,
	&:focus {
		outline: none;
		box-shadow: none;
		background: $gray-400;
	}

}

.sidebar-transition-enter {
	opacity: 0;
}

.sidebar-transition-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}

.sidebar-transition-exit {
	opacity: 1;
}

.sidebar-transition-exit-active {
	opacity: 0;
	transition: opacity 200ms;
  }

.accordion-collapse-active{
	color: #353941 !important;
    background-color: #eaedf2 !important;
    border: 0.0625rem solid #636a79 !important;
}

.sidebar-icon-active{
	color: #353941 !important;
}
