/**
 * = Paginations
 */
 .circle-pagination{
    .page-link,
    span{
        @include display-flex();
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        padding: 0;
        @include border-radius($circle-radius);
    }
}
.page-link{
  min-width:45px;
  height: 40px;
      text-align: center;
        border-radius: 8px;
}
