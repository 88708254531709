 :root{
  --borderColor:#e5e7eb;
  --tableHeader:#F8F8F8;
  --border:1.5px solid #EAEAEA;
  --dashboard:#DDDFE9;
  --black:black;
 }
 .filter-wrapper{
  border: var(--border);
  border-radius: 6px;
  padding: 16px 10px;
  margin-bottom: 20px;
 }
 .table-dashboard{
  box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
  width:100%;
  margin-top: 8px;
  background-color: var(--dashboard);
  border-radius: 6px;
  padding: 8px;
   display: flex;
  .item-wrapper{
    padding: 8px 12px;
    flex-grow: 1;
    display: flex;
    align-items: center;
   flex-direction: column;
   gap: 1px;
   justify-content: center;
    padding: 10px 0;
    border-right: 2px solid #ededed;
    &:last-child{
    border-right: none;
    }
    .item-heading{
      font-weight: 500;
      font-size: 16px;
      color: var(--black);
    }
    .item-content{
      font-size: 24px;
      font-weight: 800;
      color: var(--black);
    }

  }

 }
.custom-table{
  border-collapse: collapse;
  border: var(--border);
  border-radius: 8px;
  thead{
    th{
      border-bottom: var(--border) !important;
      padding: 17px 6px;
      background-color: var(--tableHeader);
      border-right: var(--border) !important;
      font-weight: 800;
      text-transform: capitalize !important;
      font-size: 13px;
    }
        th:first-child{
          border-radius: 8px 0 0 0;
        }
        th:last-child{
          border-radius: 0 8px 0 0;
          border-right: none !important;
        }

  }
  tbody{
    tr{
      // &.under-inspection{
      //   td{
      //     border-right: none !important;
      //   }
      // }
      td{
        border-bottom: var(--border);
        padding: 12px 6px !important;
        border-right: var(--border);
        *{
          font-size: 13px;
        }
        .anchor-tag{
          color: rgb(10, 10, 224) ;
          text-decoration: underline;
          &:hover{
            color: #1e40af;
          }
        }
      }
      td:first-child{
        // border-radius: 8px 0 0 0;
      }
      td:last-child{
        border-right: none !important;
      }
    }
  }

}
