.date-row{
  display: flex;
  gap: 10px;
  align-items: center;
}
.date-column{
  display: flex;
  flex-direction: column;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}
.date-input-wrapper{
  position: relative;
  width: 200px;
  flex-shrink: 0;
  &:disabled{
    cursor: not-allowed;
  }
  background: white;
  border: 0.0625rem solid #d3d3d3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  color: #66799e;
  img{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 15px;
    pointer-events: none;
  }
  &.full-width{
    width: 100%;
  }

}


.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-size: 0.8rem;

}
.react-datepicker,.react-datepicker__time-container .react-datepicker__time{
  background-color:white;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header ,.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name ,.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  // color: var(--bodyText);
}
.react-datepicker__day:hover,.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
  background-color:initial;
}
.react-datepicker-popper .react-datepicker__triangle{
  display: none;

}

.react-datepicker__time-container{
  border-left: 1px solid #aeaeae75;
  border-right: 1px solid #aeaeae75;
}
.react-datepicker__header {
  border-bottom: 1px solid #aeaeae75;
}



.react-datepicker__header{
  background-color:white;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #353941;
  color: white;
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #353941;
  color: white;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #353941;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #353941;
  color: white;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #353941;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #353941;
}
.react-datepicker__day--disabled{
   color:#b1adad;
  cursor: not-allowed;
}
