// =========================================================
// * AMG Platform Dashboard
// =========================================================

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

#ofBar{
    display: none !important
}

.blur-contant{
    filter: blur(0.5rem);
    padding-right: 1rem;
    cursor: pointer;
}

.border-bottom{
    border-bottom: 1px solid rgb(202 208 220)
}

.header-font-weight{
    font-weight: 800;
}
.icon_include{
  position: relative;
  img{
    position: absolute;
    right: 20px;
    top: 36px;

  }
}
.react-tel-input .flag-dropdown{
  background-color: transparent !important;
}
.react-tel-input .form-control{
  height: 43px !important;
}
tr{
  &.under-inspection{
    background-color: #f8d7da !important;
  }
}
.Toastify__toast-container{
  z-index: 10000000000 !important;
}
.form-select {
  min-width: 100px;
}
.wallet-stats{
  @media screen and (max-width:1300px) {
  flex-wrap: wrap;

  }
  .wallet-left{
    @media screen and (max-width:1300px) {
      width: 100%;
     .card{
      display: flex;
      align-items: center;
      justify-content: center;
     }

    }

  }
  .wallet-right{
    overflow-x: auto;
  }
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.match-select-wrapper{
  .custom-async-select{
    width: 100%;
    max-width: 100%;
  }
}
.table-with-max-h {
  .table-responsive{
    max-height: 500px;
    overflow-y: auto;

    thead {
      position: sticky;
      top: 0;
    }
  }
}
