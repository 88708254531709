.tenant-select{
  height: 30px;
  padding: 2px 25px 2px 20px;
  position: relative;
  bottom: 7px;
}
.tenant.main-nav{
height: 40px;
}
.tenant img{
    height: 50px;
    width: 50px;
    position: relative;
    bottom:7px
}
#notification-dropdown{
  padding: 0;
}
.notification-icon{
  .dot{
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: red;
    width: 12px;
    height: 12px;
    border-radius: 50px;
  }
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 10%);
  position: relative;
  .svg-inline--fa{
    height: 1.4rem;
  }
}
.notification-dropdown{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px !important;
  width: 100% !important;
  .dropdown-item{
    background: #eeeeee;
    border-radius: 8px;
  }
  .notification-content{
    width: 100%;
    border-radius: 6px;
    white-space: wrap;
    color: black;
    font-weight: 400;
  }
 &.dropdown-menu {
  min-width: 450px;
  &[style]{
    right: 0 !important;
    left: auto !important;
  }
 }
}
